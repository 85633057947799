import { tlogHelper } from "./tlog-helper";

const columnsMapping = {
  CURRENT_A: "current",
  VOLT_V: "volt",
  TRAVEL_SPEED: "travelSpeed",
  TRUE_ENERGY: "trueEnergy",
  DISTANCE: "distance",
  HEAT: "heat",
  WIRE_SPEED: "wireSpeed",
  OSCILLATION_WIDTH: "oscillationWidth",
  FREQUENCY: "frequency",
  TARGET: "target",
  HORIZONTAL_BIAS: "horizontalBias",
  TOTAL_WIRE_CONSUMED: "totalWireConsumed",
};

const mapTlogGraphColumns = (colorOptions, reportColumns) => {
  console.log("colorOptions | reportColumns-->", {
    colorOptions,
    reportColumns,
  });

  return colorOptions?.map((ite) => {
    let changeKeys = [
      columnsMapping.CURRENT_A,
      columnsMapping.VOLT_V,
      columnsMapping.TRAVEL_SPEED,
      columnsMapping.DISTANCE,
      columnsMapping.TRUE_ENERGY,
      columnsMapping.HEAT,
      columnsMapping.WIRE_SPEED,
      columnsMapping.OSCILLATION_WIDTH,
      columnsMapping.FREQUENCY,
      columnsMapping.TARGET,
      columnsMapping.HORIZONTAL_BIAS,
      columnsMapping.TOTAL_WIRE_CONSUMED,
    ];

    if (changeKeys.includes(ite.key)) {
      switch (ite.key) {
        case columnsMapping.CURRENT_A: {
          return {
            ...ite,
            label: `Current (${reportColumns?.units?.ArcCurrent || "A"})`,
          };
        }
        case columnsMapping.VOLT_V: {
          return {
            ...ite,
            label: `Volt (${reportColumns?.units?.ArcVolts || "V"})`,
          };
        }

        case columnsMapping.TRAVEL_SPEED: {
          return {
            ...ite,
            label: `Travel Speed (${
              reportColumns?.units?.["Travel Speed"] || "in/min"
            })`,
          };
        }

        case columnsMapping.TRUE_ENERGY: {
          return {
            ...ite,
            label: `True Energy (${
              reportColumns?.units?.["True Energy"] || "KJ/in"
            })`,
          };
        }

        case columnsMapping.HEAT: {
          return {
            ...ite,
            label: `Heat (${reportColumns?.units?.Heat || "KJ/in"})`,
          };
        }

        case columnsMapping.WIRE_SPEED: {
          return {
            ...ite,
            label: `Wire Speed (${
              reportColumns?.units?.["Wire Speed"] || "in/min"
            })`,
          };
        }

        case columnsMapping.OSCILLATION_WIDTH: {
          return {
            ...ite,
            label: `Oscillation Width (${
              reportColumns?.units?.["Oscillation Width"] || "in"
            })`,
          };
        }

        case columnsMapping.FREQUENCY: {
          return {
            ...ite,
            label: `Frequency (${
              reportColumns?.units?.Frequency || "cyc/min"
            })`,
          };
        }

        case columnsMapping.TARGET: {
          return {
            ...ite,
            label: `Target (${reportColumns?.units?.Target || "A"})`,
          };
        }

        case columnsMapping.HORIZONTAL_BIAS: {
          return {
            ...ite,
            label: `Horizontal Bias (${
              reportColumns?.units?.["Horizontal Bias"] || "A"
            })`,
          };
        }

        case columnsMapping.TOTAL_WIRE_CONSUMED: {
          return {
            ...ite,
            label: `Total Wire Consumed (${
              reportColumns?.units?.["Total Wire Consumed"] || "in"
            })`,
          };
        }

        case columnsMapping.DISTANCE: {
          return {
            ...ite,
            label: `Distance (${reportColumns?.units?.["Distance"] || "in"})`,
          };
        }
      }
    } else {
      return ite;
    }
  });
};

const mapTlogGraphUnits = (value, reportColumns) => {
  switch (value) {
    case columnsMapping.TRAVEL_SPEED: {
      return reportColumns?.units?.["Travel Speed"];
    }
    case columnsMapping.VOLT_V: {
      return reportColumns?.units?.ArcVolts;
    }

    case columnsMapping.CURRENT_A: {
      return reportColumns?.units?.ArcCurrent;
    }

    case columnsMapping.WIRE_SPEED: {
      return reportColumns?.units?.["Wire Speed"];
    }
    case columnsMapping.OSCILLATION_WIDTH: {
      return reportColumns?.units?.["Oscillation Width"];
    }
    case columnsMapping.TARGET: {
      return reportColumns?.units?.Target;
    }
    case columnsMapping.HORIZONTAL_BIAS: {
      return reportColumns?.units?.["Horizontal Bias"];
    }
    case columnsMapping.FREQUENCY: {
      return reportColumns?.units?.Frequency;
    }
    case columnsMapping.TOTAL_WIRE_CONSUMED: {
      return reportColumns?.units?.["Total Wire Consumed"];
    }
    case columnsMapping.TRUE_ENERGY: {
      return reportColumns?.units?.["True Energy"];
    }
    case columnsMapping.HEAT: {
      return reportColumns?.units?.Heat;
    }
    default: {
      return "";
    }
  }
};

const getMinMaxValuesForGraph = ({
  column,
  tlogData,
  tableLimitData,
  type,
  reportColumns,
}) => {
  let avgTlogItem = tlogData[0];

  if (!avgTlogItem?.trail_pass_name && !avgTlogItem?.lead_pass_name) {
    return {
      min: 0,
      max: 0,
    };
  }

  let passNameExtracted = "";
  if (type == "Lead") {
    passNameExtracted = avgTlogItem?.lead_pass_name;
  } else if (type == "Trail") {
    passNameExtracted = avgTlogItem?.trail_pass_name;
  } else {
    passNameExtracted =
      avgTlogItem?.trail_pass_name || avgTlogItem?.lead_pass_name;
  }
  if (passNameExtracted.includes("Head")) {
    passNameExtracted = "HEAD";
  }

  let job_number = avgTlogItem?.job_number;

  let allTableLimitZones = [];

  if (job_number) {
    allTableLimitZones = tableLimitData?.filter(
      (tableLimit) => tableLimit.job_number == job_number
    );
  }

  allTableLimitZones = allTableLimitZones?.filter(
    (tableLimit) => tableLimit.zone_name == passNameExtracted
  );

  if (!allTableLimitZones || allTableLimitZones.length === 0) {
    return { min: 0, max: 0 };
  }

  const minmaxValue = allTableLimitZones[0];

  // Normalize the column name to snake_case if necessary
  const normalizedColumn = column;

  const minKeyResolved = `${normalizedColumn}_min`;
  const maxKeyResolved = `${normalizedColumn}_max`;

  const minValue = minmaxValue?.[minKeyResolved];
  const maxValue = minmaxValue?.[maxKeyResolved];

  if (minValue !== undefined && maxValue !== undefined) {
    const calculatedMin = tlogHelper.rangeCalculation(
      minValue,
      column,
      reportColumns,
      false
    );
    const calculatedMax = tlogHelper.rangeCalculation(
      maxValue,
      column,
      reportColumns,
      false
    );

    return {
      min: calculatedMin,
      max: calculatedMax,
    };
  } else {
    return { min: 0, max: 0 };
  }
};

export const tlogGraphHelper = {
  mapTlogGraphColumns,
  columnsMapping,
  mapTlogGraphUnits,
  getMinMaxValuesForGraph,
};
